export default {
  validate: (data) => {
    let validates = [];

    for (const element of data) {
      const item = element;
      if (!item.data) {
        item.ref.setInvalid(true);
        validates.push(false);
      } else {
        validates.push(true);
      }
    }

    let isValid = validates.every((value) => {
      return value === true;
    });

    return isValid;
  },
};
